import * as React from "react"
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import { Link } from 'gatsby'


const styles = require('./Index.module.css')  
const config = require('../../data/siteConfig')


const AboutPage = () => {
  const pageTitle="Об издании";

  return (
    <Layout>
      <div className = "page">
        <Helmet defer={false}>
          <title>{pageTitle} | {config.siteTitle}</title>              
          <link rel="canonical" href={`${config.siteUrl}/about/`} />
        </Helmet>
        <div className={`${styles.pageContent} pageContent`}>
          <h1>{pageTitle}</h1>
          <div className = "article">
           <p>Настоящим электронным изданием мы - группа подвижников общества Сатсанга - приступаем к публикации записей бесед и занятий с <Link to="/ruben-zakharbekov">Рубеном Захарбековым (Шри Парамахансой Рамакришной)</Link>, посвященных постижению Святого Писания.</p>
           <p>Мы не ставим перед собой цели передачи какого-то определённого знания или понимания Святого Писания. Наша основная цель - поделиться бесценным даром, наследием нашего Учителя - опытом проникновения в смысл Писания “изнутри” -  так, как если бы это Оно было ниспослано лично нам, вам, каждому, кто пытается постичь Истину.</p>
           <p>Комментарий построен так, что читать его можно с любого места, также, впрочем, как и Святое Писание. Мы постарались снабдить комментариями и ссылками места, которые могут показаться читателю непонятными или неоднозначными. Изложенный материал рассчитан на широкий круг читателей и не требует специальной подготовки или академических знаний. При редактировании мы старались максимально соблюсти точность понятий и аккуратно изложить суть достаточно сложных идей и концепций. Единственный совет, который мы можем дать читателю: не спешите. Дайте себе время на то, чтобы по-настоящему выносить строки Писания, так чтобы в вас родился ваш собственный комментарий, ваше собственное видение и слышание слова Бога.</p>
           <p>Вот как сам Учитель сформулировал суть своего подхода к изучению Святых Писаний:</p>
          <blockquote className="intext">
            <p>...Это не просто заучивание Святого Писания, не просто комментарий к Святому Писанию, не просто трактовка Святого Писания, нет - это уже делали до меня миллионы людей. Зачем мне повторять? Я, наверное, сделаю это хуже, чем самые лучшие из них. А зачем это делать, если важно-то на самом деле другое?! Важно не развить ваш ум, не развить вас интеллектуально, чтобы вы были энциклопедически образованными, хотя другой возможности нет, вы все равно станете эрудированными (в процессе), но цель-то в другом! Цель в том, чтобы вы привели себя в такое состояние, чтобы написанные строки истекали из вашего сознания!</p>
            <p>Я вас уверяю, что вы постигнете истину в тот момент, когда Писание начнет литься из ваших уст, когда вы вдруг поймете, что можно сказать это, только это и ничего кроме этого. У Вас нет других слов, оно истекает из вас само помимо вашей воли, когда вы видите, что поток оформлен так и только так и никак иначе, и вы сами - автор этого потока.</p>
            <p>Я полагаю, что могут быть с этим какие-то трудности, но хотя бы к этому нужно стремиться.</p>
          <cite>Рубен Захарбеков (Шри Парамаханса Рамакришна)</cite>
          </blockquote>
          <p>Имена, фигурирующие в данном издании - это духовные имена подвижников, данные Учителем. (Читать подробнее: Как даётся духовное имя, и что оно означает). Только самого Учителя в тексте Комментария мы будем называть Рубиком, точно так, как мы сами обращались к нему - любовно и просто - как к самому близкому и родному человеку.</p>

          <h2>О Сатсанге</h2>

          <p>Общество Сатсанга было основано Рубиком по просьбе учеников для организации  регулярных занятий и совместных поездок. Название Сатсанга происходит от соединения двух санскритских слов: сат (истинно-сущее,  одно из определений Абсолюта) и санга (единение) и буквально переводится как содружество или соединение с Истиной. Точно так же называл свою домашнюю организацию Свами Шри Юктешвар Гири, учитель Парамахансы Йогананды. Две "Сатсанги" очень близки по духу, мы привержены традициям всех религий, которые исповедуют истинную Любовь, ту Любовь, которая и есть - Бог.</p>
          <p className="alignRigt">Общество “Сатсанга” <br/><em>Посвящается всем, кто ищет Бога</em></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutPage;

